<template>
  <div>
    <!-- 场地管理员 -->
    <div class="top">
      <span class="l">人员管理</span>
      <el-button
        type="primary"
        size="small"
        @click="
          dialogFormVisible = true;
          type = 'add';
        "
        >新增角色</el-button
      >
    </div>
    <el-table :data="tableData" border style="width:100%;margin-top:30px;max-height:70vh;overflow-Y:scroll">
      <el-table-column prop="user_name" label="用户名"></el-table-column>
      <el-table-column label="头像">
        <template #default="scope">
          <img
            :src="scope.row.head_pic"
            alt
            style="width:50px;height:50px;border-radius:50%"
            v-if="scope.row.head_pic"
          />
          <span v-else></span>
        </template>
      </el-table-column>
      <el-table-column prop="role_type_name" label="角色名称"></el-table-column>
      <el-table-column prop="mobile" label="电话号码"></el-table-column>
      <el-table-column prop="created_at" label="创建时间"></el-table-column>
      <el-table-column label="暂停使用">
        <template #default="scope">
          <el-switch v-model="scope.row.status" active-color="#13ce66" @change="switchChange(scope.row)"></el-switch>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="200px">
        <template #default="scope">
          <el-button type="danger" size="small" @click.prevent="deleteRow(scope.$index, tableData)">删除</el-button>
          <el-button type="primary" size="small" @click="handleClick(scope.row, scope.$index)">编辑</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog :title="type == 'add' ? '增加角色' : '编辑角色'" v-model="dialogFormVisible" width="25%">
      <el-form :model="form">
        <el-form-item label="角色" :label-width="formLabelWidth" v-if="type == 'add'">
          <el-select placeholder="请选择角色" v-model="form.role_type">
            <el-option label="员工" value="2"></el-option>
            <el-option label="管理员" value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="账号" :label-width="formLabelWidth" v-if="form.role_type != 2">
          <el-input autocomplete="off" placeholder="请输入登录账号" v-model="form.mobile"></el-input>
        </el-form-item>
        <el-form-item label="密码" :label-width="formLabelWidth" v-if="form.role_type != 2">
          <el-input autocomplete="off" placeholder="请输入登录密码" v-model="form.password"></el-input>
        </el-form-item>
        <el-form-item label="二微码" :label-width="formLabelWidth" v-if="form.role_type == 2">
          <qrcode-vue :value="value" size="200" level="H" />
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button
            @click="
              dialogFormVisible = false;
              form = {};
            "
            >取 消</el-button
          >
          <el-button type="primary" @click="addcurse()">确 定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import QrcodeVue from "qrcode.vue";
import { ElMessage } from "element-plus";
import { propertyList, propertyFirst, propertyAdd, propertyDel, propertyEdit, updateStatus } from "../../request/api";
export default {
  async created() {
    this.value = this.value + "address_id=" + this.$route.query.address_id;
    this.address_id = this.$route.query.address_id;
    this.form.address_id = this.$route.query.address_id;
    this.pagetotal = await this.admList();
    this.pagetotal ? this.$emit("pagetotalClick", this.pagetotal) : "";
  },
  props: ["page", "pagepage"],
  components: {
    QrcodeVue,
  },
  data() {
    return {
      address_id: "",
      dialogFormVisible: false,
      type: "add",
      pagetotal: 0,
      formLabelWidth: "60px",
      value: "https://ucd.rongxinh.com/add_personnel/?",
      size: 10,
      form: {
        role_type: "",
        role_type_name: "管理员",
        role_num: 1,
        mobile: "",
        password: "",
        address_id: "",
      },
      tableData: [
        {
          address_name: "张三",
          addressL: "",
          address: "管理员",
          address_type_name: "18382404250",
        },
      ],
    };
  },
  methods: {
    switchChange(item) {
      updateStatus({
        id: item.id,
        status: item.status == true ? 1 : 2,
      }).then((res) => {
        if (res.code == 200) {
          this.$message({
            type: res.code == 200 ? "success" : "info",
            message: res.msg,
          });
        }
      });
    },
    admList() {
      this.tableData = [];
      return new Promise((resolve, reject) => {
        propertyList({
          page: this.page,
          limit: this.pagepage,
          address_id: this.address_id,
        }).then((res) => {
          resolve(res.total);
          this.tableData = res.list.address_manager;
          this.tableData.forEach((item) => {
            item.status = item.status == 2 ? false : true;
          });
        });
      });
    },
    addcurse() {
      if (this.type == "add" && this.form.role_type != 2) {
        propertyAdd(this.form).then((res) => {
          if (res.code == 200) {
            this.admList();
            this.$emit("pagetotalClick", this.pagetotal + 1);
            ElMessage.success({
              message: res.msg,
              type: "success",
            });
            this.dialogFormVisible = false;
            this.form = {};
          } else {
            ElMessage.error(res.msg);
          }
        });
      } else if (this.type == "revise") {
        propertyEdit({ password: this.form.password, mobile: this.form.mobile }).then((res) => {
          if (res.code == 200) {
            this.admList();
            ElMessage.success({
              message: res.msg,
              type: "success",
            });
            this.dialogFormVisible = false;
          } else {
            ElMessage.error(res.msg);
          }
        });
      } else {
        this.dialogFormVisible = false;
      }
    },
    handleClick(item, index) {
      this.dialogFormVisible = true;
      this.form.mobile = item.mobile;
      this.type = "revise";
    },
    //   删除
    deleteRow(index, rows) {
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          propertyDel({ id: rows[index].id }).then((res) => {
            if (res.code == 200) {
              this.admList();
              this.$emit("pagetotalClick", this.pagetotal - 1);
              rows.splice(index, 1);
              this.$message({
                type: "success",
                message: "删除成功!",
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
};
</script>

<style lang="less" scoped>
.el-input {
  width: 250px;
}
.el-form-item {
  display: flex;
}
.top {
  .l {
    font-size: 20px;
    font-weight: 400;
    color: #545454;
  }
  display: flex;
  justify-content: space-between;
}
</style>
